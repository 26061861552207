export const svgs: Record<string, JSX.Element> = {
  hamburger: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <symbol id="icon-hamburger" viewBox="0 0 32 32">
          <path d="M26.494 22.792c1.145 0 2.074 0.941 2.074 2.102 0 1.103-0.838 2.007-1.903 2.095l-0.17 0.007h-20.988c-1.145 0-2.074-0.941-2.074-2.102 0-1.103 0.838-2.007 1.903-2.095l0.17-0.007h20.988zM26.494 14.384c1.145 0 2.074 0.941 2.074 2.102 0 1.103-0.838 2.007-1.903 2.095l-0.17 0.007h-20.988c-1.145 0-2.074-0.941-2.074-2.102 0-1.103 0.838-2.007 1.903-2.095l0.17-0.007h20.988zM26.494 5.004c1.145 0 2.074 0.941 2.074 2.102 0 1.103-0.838 2.007-1.903 2.095l-0.17 0.007h-20.988c-1.145 0-2.074-0.941-2.074-2.102 0-1.103 0.838-2.007 1.903-2.095l0.17-0.007h20.988z"></path>
        </symbol>
      </defs>
      <use xlinkHref="#icon-hamburger" fill="currentColor"></use>
    </svg>
  ),
  cross: (
    <svg style={{ width: "inherit", height: "inherit" }} viewBox="7 7 10 10">
      <path
        d="M12 10.8891L15.8891 7L17 8.11094L13.1109 12L17 15.8891L15.8891 17L12 13.1109L8.11094 17L7 15.8891L10.8891 12L7 8.11094L8.11094 7L12 10.8891Z"
        fill="currentColor"
      />
    </svg>
  ),
  pin: (
    <svg
      style={{ width: "inherit", height: "inherit" }}
      viewBox="5 2 15 19"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <g transform="translate(2.5 2.5) scale(0.25 0.25)">
        <path
          d="M67.9355 31.6666C67.9355 53.8333 38.9971 72.8333 38.9971 72.8333C38.9971 72.8333 10.0586 53.8333 10.0586 31.6666C10.0586 24.108 13.1075 16.8589 18.5345 11.5141C23.9615 6.1693 31.3221 3.16663 38.9971 3.16663C46.672 3.16663 54.0326 6.1693 59.4596 11.5141C64.8866 16.8589 67.9355 24.108 67.9355 31.6666Z"
          stroke="white"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.9977 41.1666C44.3251 41.1666 48.6439 36.9133 48.6439 31.6666C48.6439 26.4199 44.3251 22.1666 38.9977 22.1666C33.6703 22.1666 29.3516 26.4199 29.3516 31.6666C29.3516 36.9133 33.6703 41.1666 38.9977 41.1666Z"
          stroke="white"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  ),
  chevron_down: (
    <svg
      viewBox="1 -2 10 10"
      xmlns="http://www.w3.org/2000/svg"
      stroke="1"
      fill="none"
    >
      <path d="M1 1L5.5 5.5L10 1" stroke="currentColor" />
    </svg>
  ),
  calendar: (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "inherit", height: "inherit" }}
      viewBox="0 0 610.398 610.398"
      xmlSpace="preserve"
    >
      <path
        d="M159.567,0h-15.329c-1.956,0-3.811,0.411-5.608,0.995c-8.979,2.912-15.616,12.498-15.616,23.997v10.552v27.009v14.052
			c0,2.611,0.435,5.078,1.066,7.44c2.702,10.146,10.653,17.552,20.158,17.552h15.329c11.724,0,21.224-11.188,21.224-24.992V62.553
			V35.544V24.992C180.791,11.188,171.291,0,159.567,0z"
      />
      <path
        d="M461.288,0h-15.329c-11.724,0-21.224,11.188-21.224,24.992v10.552v27.009v14.052c0,13.804,9.5,24.992,21.224,24.992
			h15.329c11.724,0,21.224-11.188,21.224-24.992V62.553V35.544V24.992C482.507,11.188,473.007,0,461.288,0z"
      />
      <path
        d="M539.586,62.553h-37.954v14.052c0,24.327-18.102,44.117-40.349,44.117h-15.329c-22.247,0-40.349-19.79-40.349-44.117
			V62.553H199.916v14.052c0,24.327-18.102,44.117-40.349,44.117h-15.329c-22.248,0-40.349-19.79-40.349-44.117V62.553H70.818
			c-21.066,0-38.15,16.017-38.15,35.764v476.318c0,19.784,17.083,35.764,38.15,35.764h468.763c21.085,0,38.149-15.984,38.149-35.764
			V98.322C577.735,78.575,560.671,62.553,539.586,62.553z M527.757,557.9l-446.502-0.172V173.717h446.502V557.9z"
      />
      <path
        d="M353.017,266.258h117.428c10.193,0,18.437-10.179,18.437-22.759s-8.248-22.759-18.437-22.759H353.017
			c-10.193,0-18.437,10.179-18.437,22.759C334.58,256.074,342.823,266.258,353.017,266.258z"
      />
      <path
        d="M353.017,348.467h117.428c10.193,0,18.437-10.179,18.437-22.759c0-12.579-8.248-22.758-18.437-22.758H353.017
			c-10.193,0-18.437,10.179-18.437,22.758C334.58,338.288,342.823,348.467,353.017,348.467z"
      />
      <path
        d="M353.017,430.676h117.428c10.193,0,18.437-10.18,18.437-22.759s-8.248-22.759-18.437-22.759H353.017
			c-10.193,0-18.437,10.18-18.437,22.759S342.823,430.676,353.017,430.676z"
      />
      <path
        d="M353.017,512.89h117.428c10.193,0,18.437-10.18,18.437-22.759c0-12.58-8.248-22.759-18.437-22.759H353.017
			c-10.193,0-18.437,10.179-18.437,22.759C334.58,502.71,342.823,512.89,353.017,512.89z"
      />
      <path
        d="M145.032,266.258H262.46c10.193,0,18.436-10.179,18.436-22.759s-8.248-22.759-18.436-22.759H145.032
			c-10.194,0-18.437,10.179-18.437,22.759C126.596,256.074,134.838,266.258,145.032,266.258z"
      />
      <path
        d="M145.032,348.467H262.46c10.193,0,18.436-10.179,18.436-22.759c0-12.579-8.248-22.758-18.436-22.758H145.032
			c-10.194,0-18.437,10.179-18.437,22.758C126.596,338.288,134.838,348.467,145.032,348.467z"
      />
      <path
        d="M145.032,430.676H262.46c10.193,0,18.436-10.18,18.436-22.759s-8.248-22.759-18.436-22.759H145.032
			c-10.194,0-18.437,10.18-18.437,22.759S134.838,430.676,145.032,430.676z"
      />
      <path
        d="M145.032,512.89H262.46c10.193,0,18.436-10.18,18.436-22.759c0-12.58-8.248-22.759-18.436-22.759H145.032
			c-10.194,0-18.437,10.179-18.437,22.759C126.596,502.71,134.838,512.89,145.032,512.89z"
      />
    </svg>
  ),
  clock: (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      style={{ width: "inherit", height: "inherit" }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="currentColor" />
      <path
        d="M8 3V7.75C8 7.88807 8.11193 8 8.25 8H11"
        stroke="#fff"
        strokeLinecap="round"
      />
    </svg>
  ),
  petrol: (
    <svg
      style={{ width: "inherit", height: "inherit" }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 6H8C7.05719 6 6.58579 6 6.29289 6.29289C6 6.58579 6 7.05719 6 8C6 8.94281 6 9.41421 6.29289 9.70711C6.58579 10 7.05719 10 8 10H11C11.9428 10 12.4142 10 12.7071 9.70711C13 9.41421 13 8.94281 13 8C13 7.05719 13 6.58579 12.7071 6.29289C12.4142 6 11.9428 6 11 6Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M7 17H12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M17 22H2"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M19.5 4L20.7331 4.98647C20.8709 5.09673 20.9398 5.15186 21.0025 5.20805C21.5937 5.73807 21.9508 6.48086 21.9953 7.27364C22 7.35769 22 7.44594 22 7.62244V18.5C22 19.3284 21.3284 20 20.5 20C19.6716 20 19 19.3284 19 18.5V18.4286C19 17.6396 18.3604 17 17.5714 17H16"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M22 8H20.5C19.6716 8 19 8.67157 19 9.5V11.9189C19 12.5645 19.4131 13.1377 20.0257 13.3419L22 14"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16 22V15M3 22V18M3 14V8C3 5.17157 3 3.75736 3.87868 2.87868C4.75736 2 6.17157 2 9 2H10C12.8284 2 14.2426 2 15.1213 2.87868C16 3.75736 16 5.17157 16 8V11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  ),
  transmission: (
    <svg
      style={{ width: "inherit", height: "inherit" }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 4C6 5.10457 5.10457 6 4 6C2.89543 6 2 5.10457 2 4C2 2.89543 2.89543 2 4 2C5.10457 2 6 2.89543 6 4Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M6 20C6 21.1046 5.10457 22 4 22C2.89543 22 2 21.1046 2 20C2 18.8954 2.89543 18 4 18C5.10457 18 6 18.8954 6 20Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M14 20C14 21.1046 13.1046 22 12 22C10.8954 22 10 21.1046 10 20C10 18.8954 10.8954 18 12 18C13.1046 18 14 18.8954 14 20Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M14 4C14 5.10457 13.1046 6 12 6C10.8954 6 10 5.10457 10 4C10 2.89543 10.8954 2 12 2C13.1046 2 14 2.89543 14 4Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M22 4C22 5.10457 21.1046 6 20 6C18.8954 6 18 5.10457 18 4C18 2.89543 18.8954 2 20 2C21.1046 2 22 2.89543 22 4Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M12 6V13M12 18V16"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4 18V11M4 6V8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20 6V8C20 9.88562 20 10.8284 19.4142 11.4142C18.8284 12 17.8856 12 16 12H10M4 12H6"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18 15V14.25C17.5858 14.25 17.25 14.5858 17.25 15H18ZM17.25 22C17.25 22.4142 17.5858 22.75 18 22.75C18.4142 22.75 18.75 22.4142 18.75 22H17.25ZM21.3604 22.3916C21.5766 22.7449 22.0384 22.8559 22.3916 22.6396C22.7449 22.4234 22.8559 21.9616 22.6396 21.6084L21.3604 22.3916ZM18 15.75H20.2857V14.25H18V15.75ZM18.75 18.5V15H17.25V18.5H18.75ZM21.25 16.75C21.25 17.3169 20.8038 17.75 20.2857 17.75V19.25C21.6612 19.25 22.75 18.1161 22.75 16.75H21.25ZM20.2857 15.75C20.8038 15.75 21.25 16.1831 21.25 16.75H22.75C22.75 15.3839 21.6612 14.25 20.2857 14.25V15.75ZM20.2857 17.75H19.8571V19.25H20.2857V17.75ZM19.8571 17.75H18V19.25H19.8571V17.75ZM19.2175 18.8916L21.3604 22.3916L22.6396 21.6084L20.4968 18.1084L19.2175 18.8916ZM17.25 18.5V22H18.75V18.5H17.25Z"
        fill="currentColor"
      />
    </svg>
  ),
  seat: (
    <svg
      fill="currentColor"
      style={{ width: "inherit", height: "inherit" }}
      version="1.1"
      id="Layer_1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path
        d="M432.613,348.736c-3.872-1.706-7.722-3.008-11.492-3.981c1.68-7.537,3.097-15.447,4.276-23.675
			c2.091-14.558,1.532-29.479-1.576-44.059l-26.646-124.91c-4.613-16.407-13.781-29.182-26.559-38.998
			c7.581-9.664,10.672-22.816,6.732-35.738l-8.005-47.968l-1.042-3.912C362.625,10.203,348.073,0,331.767,0H180.258
			c-16.29,0-30.824,10.181-36.535,25.44l-13.86,51.58c-5.074,13.54-1.075,28.288,8.384,38.424
			c-11.093,9.27-19.061,20.91-23.129,35.322L88.12,277.014c-3.109,14.586-3.668,29.508-1.576,44.073
			c1.179,8.232,2.597,16.146,4.278,23.686c-3.744,0.971-7.566,2.267-11.41,3.96c-22.543,9.934-36.731,30.48-36.731,60.325
			c0.683,14.501,5.085,33.088,16.639,51.945c19.423,31.701,52.814,50.92,100.452,50.985c0.077,0.001,0.152,0.012,0.23,0.012h192
			c0.056,0,0.109-0.008,0.165-0.008c47.675-0.042,81.092-19.266,100.529-50.985c11.555-18.856,15.961-37.441,16.622-50.9
			C469.344,379.216,455.157,358.67,432.613,348.736z M183.174,42.667h145.125l7.12,42.667h-67.644c-2.425-0.032-4.808-0.032-7.136,0
			h-88.924L183.174,42.667z M129.848,285.915l26.665-124.9c3.386-11.834,18.426-21.817,43.274-27.72
			c10.376-2.465,21.666-4.008,33.2-4.769c4.929-0.325,9.502-0.485,13.577-0.526h3.889c1.062,0.01,2.095,0.025,3.064,0.051
			c1.298,0.041,1.298,0.041,1.399,0.047c2.208-0.006,2.208-0.006,3.506-0.047c0.969-0.026,2.003-0.041,3.064-0.051h3.886
			c4.077,0.041,8.653,0.201,13.584,0.526c11.537,0.761,22.83,2.305,33.208,4.771c24.848,5.905,39.893,15.889,43.617,29.066
			l26.311,123.556c2.07,9.712,2.439,19.571,1.07,29.103c-5.363,37.44-17.238,63.667-32.217,68.25
			c-0.151,0.041-0.299,0.092-0.451,0.129c-0.039,0.011-0.079,0.018-0.118,0.029c-0.394,0.092-0.795,0.164-1.197,0.228
			c-6.045-2.153-14.371-4.742-24.541-7.304c-22.151-5.581-45.354-8.935-68.668-8.935c-23.304,0-46.505,3.354-68.659,8.934
			c-10.029,2.526-18.255,5.076-24.28,7.211c-0.089,0.031-0.187,0.065-0.275,0.096c-0.38-0.062-0.759-0.129-1.132-0.216
			c-0.054-0.014-0.109-0.025-0.164-0.04c-0.163-0.04-0.322-0.094-0.484-0.139c-14.971-4.599-26.838-30.818-32.199-68.237
			C127.408,305.488,127.778,295.628,129.848,285.915z M95.702,438.713c-4.196-6.849-7.092-14.33-8.867-21.821
			c-1.036-4.373-1.448-7.558-1.512-8.876c0.025-11.431,3.838-16.953,11.295-20.239c1.817-0.801,3.734-1.405,5.62-1.84
			c3.782,5.992,8.006,11.337,12.687,15.987c6.633,8.433,14.486,14.948,23.744,19.173v46.323
			C118.265,463.433,104.671,453.353,95.702,438.713z M181.348,469.333v-46.897c0.018-0.006,0.036-0.012,0.054-0.018
			c1.149-0.374,2.332-0.748,3.544-1.121c0.107-0.033,0.213-0.066,0.32-0.099c1.215-0.372,2.46-0.743,3.735-1.111
			c0.091-0.026,0.184-0.053,0.275-0.079c2.691-0.774,5.509-1.536,8.447-2.276c18.976-4.779,38.786-7.642,58.256-7.642
			c19.461,0,39.264,2.862,58.237,7.642c4.497,1.133,8.718,2.318,12.621,3.516c0.021,0.006,0.042,0.013,0.062,0.019
			c1.29,0.396,2.545,0.794,3.764,1.191c0.001,0,0.003,0.001,0.004,0.001v46.873H181.348z M425.189,416.89
			c-1.776,7.492-4.674,14.974-8.871,21.824c-8.973,14.642-22.569,24.724-42.973,28.71v-46.36
			c9.028-4.135,16.716-10.453,23.24-18.602c4.89-4.767,9.285-10.289,13.21-16.52c1.884,0.435,3.798,1.039,5.614,1.839
			c7.457,3.286,11.269,8.808,11.269,21.281C426.638,409.33,426.225,412.517,425.189,416.89z"
      />
    </svg>
  ),
  phone: (
    <svg
      fill="currentColor"
      style={{ width: "inherit", height: "inherit" }}
      viewBox="0 0 473.806 473.806"
      xmlSpace="preserve"
    >
      <path
        d="M374.456,293.506c-9.7-10.1-21.4-15.5-33.8-15.5c-12.3,0-24.1,5.3-34.2,15.4l-31.6,31.5c-2.6-1.4-5.2-2.7-7.7-4
			c-3.6-1.8-7-3.5-9.9-5.3c-29.6-18.8-56.5-43.3-82.3-75c-12.5-15.8-20.9-29.1-27-42.6c8.2-7.5,15.8-15.3,23.2-22.8
			c2.8-2.8,5.6-5.7,8.4-8.5c21-21,21-48.2,0-69.2l-27.3-27.3c-3.1-3.1-6.3-6.3-9.3-9.5c-6-6.2-12.3-12.6-18.8-18.6
			c-9.7-9.6-21.3-14.7-33.5-14.7s-24,5.1-34,14.7c-0.1,0.1-0.1,0.1-0.2,0.2l-34,34.3c-12.8,12.8-20.1,28.4-21.7,46.5
			c-2.4,29.2,6.2,56.4,12.8,74.2c16.2,43.7,40.4,84.2,76.5,127.6c43.8,52.3,96.5,93.6,156.7,122.7c23,10.9,53.7,23.8,88,26
			c2.1,0.1,4.3,0.2,6.3,0.2c23.1,0,42.5-8.3,57.7-24.8c0.1-0.2,0.3-0.3,0.4-0.5c5.2-6.3,11.2-12,17.5-18.1c4.3-4.1,8.7-8.4,13-12.9
			c9.9-10.3,15.1-22.3,15.1-34.6c0-12.4-5.3-24.3-15.4-34.3L374.456,293.506z M410.256,398.806
			C410.156,398.806,410.156,398.906,410.256,398.806c-3.9,4.2-7.9,8-12.2,12.2c-6.5,6.2-13.1,12.7-19.3,20
			c-10.1,10.8-22,15.9-37.6,15.9c-1.5,0-3.1,0-4.6-0.1c-29.7-1.9-57.3-13.5-78-23.4c-56.6-27.4-106.3-66.3-147.6-115.6
			c-34.1-41.1-56.9-79.1-72-119.9c-9.3-24.9-12.7-44.3-11.2-62.6c1-11.7,5.5-21.4,13.8-29.7l34.1-34.1c4.9-4.6,10.1-7.1,15.2-7.1
			c6.3,0,11.4,3.8,14.6,7c0.1,0.1,0.2,0.2,0.3,0.3c6.1,5.7,11.9,11.6,18,17.9c3.1,3.2,6.3,6.4,9.5,9.7l27.3,27.3
			c10.6,10.6,10.6,20.4,0,31c-2.9,2.9-5.7,5.8-8.6,8.6c-8.4,8.6-16.4,16.6-25.1,24.4c-0.2,0.2-0.4,0.3-0.5,0.5
			c-8.6,8.6-7,17-5.2,22.7c0.1,0.3,0.2,0.6,0.3,0.9c7.1,17.2,17.1,33.4,32.3,52.7l0.1,0.1c27.6,34,56.7,60.5,88.8,80.8
			c4.1,2.6,8.3,4.7,12.3,6.7c3.6,1.8,7,3.5,9.9,5.3c0.4,0.2,0.8,0.5,1.2,0.7c3.4,1.7,6.6,2.5,9.9,2.5c8.3,0,13.5-5.2,15.2-6.9
			l34.2-34.2c3.4-3.4,8.8-7.5,15.1-7.5c6.2,0,11.3,3.9,14.4,7.3c0.1,0.1,0.1,0.1,0.2,0.2l55.1,55.1
			C420.456,377.706,420.456,388.206,410.256,398.806z"
      />
      <path
        d="M256.056,112.706c26.2,4.4,50,16.8,69,35.8s31.3,42.8,35.8,69c1.1,6.6,6.8,11.2,13.3,11.2c0.8,0,1.5-0.1,2.3-0.2
			c7.4-1.2,12.3-8.2,11.1-15.6c-5.4-31.7-20.4-60.6-43.3-83.5s-51.8-37.9-83.5-43.3c-7.4-1.2-14.3,3.7-15.6,11
			S248.656,111.506,256.056,112.706z"
      />
      <path
        d="M473.256,209.006c-8.9-52.2-33.5-99.7-71.3-137.5s-85.3-62.4-137.5-71.3c-7.3-1.3-14.2,3.7-15.5,11
			c-1.2,7.4,3.7,14.3,11.1,15.6c46.6,7.9,89.1,30,122.9,63.7c33.8,33.8,55.8,76.3,63.7,122.9c1.1,6.6,6.8,11.2,13.3,11.2
			c0.8,0,1.5-0.1,2.3-0.2C469.556,223.306,474.556,216.306,473.256,209.006z"
      />
    </svg>
  ),
  mail: (
    <svg
      style={{ width: "inherit", height: "inherit" }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 18L9 12M20 18L15 12M3 8L10.225 12.8166C10.8665 13.2443 11.1872 13.4582 11.5339 13.5412C11.8403 13.6147 12.1597 13.6147 12.4661 13.5412C12.8128 13.4582 13.1335 13.2443 13.775 12.8166L21 8M6.2 19H17.8C18.9201 19 19.4802 19 19.908 18.782C20.2843 18.5903 20.5903 18.2843 20.782 17.908C21 17.4802 21 16.9201 21 15.8V8.2C21 7.0799 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V15.8C3 16.9201 3 17.4802 3.21799 17.908C3.40973 18.2843 3.71569 18.5903 4.09202 18.782C4.51984 19 5.07989 19 6.2 19Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  model: (
    <svg
      fill="currentColor"
      style={{ width: "inherit", height: "inherit" }}
      viewBox="0 0 192.962 192.961"
      xmlSpace="preserve"
    >
      <path
        d="M183.917,76.845c-3.215-1.168-8.529-2.251-11.889-0.314c-0.033-0.109-0.058-0.218-0.091-0.326
		c-5.549-18.288-16.843-38.273-36.004-44.819c-7.294-2.492-14.644-3.776-22.031-4.297c-0.225-2.475-0.66-4.934-0.81-7.419
		c-0.07-1.164-0.868-1.928-2.018-2.017c-8.415-0.649-16.36-2.265-24.811-0.502c-6.404,1.336-10.114,6.222-8.442,11.608
		c-4.003,0.447-8.008,0.912-12.014,1.331c-0.314,0.033-0.573,0.142-0.825,0.257c-13.388,0.131-25.265,16.844-32.289,26.247
		c-3.401,4.553-6.335,9.458-8.87,14.587c-3.979-1.814-9.429-1.61-13.206-0.52C4.785,72.345,1.58,77.82,0.317,83.392
		c-1.775,7.833,4.067,21.893,13.413,19.327c-1.225,6.894-1.966,13.84-2.132,20.674c-0.211,8.678,1.717,14.914,5.718,19.209
		c-2.883,1.65-2.68,8.31-2.625,10.808c0.158,7.207,3.804,13.272,9.657,17.309c14.312,9.872,31.807-3.227,28.229-19.427
		c-0.01-0.046-0.042-0.073-0.054-0.118c25.305,1.029,50.644-0.312,75.959-0.125c1.378,0.011,2.858,0.055,4.381,0.105
		c-2.024,13.169,8.025,26.124,21.714,25.428c14.765-0.75,19.633-18.474,14.968-30.493c3.008-2.004,5.514-4.686,7.18-8.377
		c3.433-7.607,1.674-17.549,0.994-25.512c-0.208-2.431-0.426-4.844-0.659-7.249c2.992,0.218,6.471-1.265,8.635-2.822
		c4.436-3.19,7.172-8.337,7.267-13.779C193.052,83.125,188.564,78.533,183.917,76.845z M84.002,23.185
		c2.575-1.635,6.199-1.818,9.14-2.003c5.226-0.328,10.734-0.235,16.003,0.312c0.076,1.792,0.182,3.583,0.318,5.373
		c-9.125-0.284-18.303,0.466-27.502,1.444C81.646,26.322,81.839,24.558,84.002,23.185z M5.568,83.893
		c1.082-4.656,3.98-7.75,8.604-8.876c2.684-0.654,5.133,0.125,7.616,0.708c-3.288,7.538-5.75,15.47-7.412,23.564
		C7.183,98.751,4.031,90.51,5.568,83.893z M26.962,166.245c-7.917-4.262-8.156-13.669-7.081-21.374
		c4.086,3.023,9.651,4.719,16.72,5.338c3.502,0.308,7.004,0.536,10.508,0.729c-0.119,0.327-0.171,0.693-0.122,1.108
		C48.446,164.48,38.806,172.622,26.962,166.245z M155.601,170.583c-12.24,2.785-16.789-9.932-19.014-19.298
		c9.103,0.33,19.634,0.543,27.949-2.622C165.326,157.442,166.497,168.103,155.601,170.583z M169.919,137.191
		c-4.838,10.415-26.625,7.695-35.445,7.913c-18.575,0.456-37.126,0.812-55.698,1.274c-15.655,0.391-56.737,3.369-61.631-15.502
		c-4.612-17.786,3.65-42.683,11.419-58.429c7.472-15.145,19.738-31.418,35.985-37.568c0.348,0.177,0.748,0.292,1.26,0.237
		c35.875-3.838,72.982-8.457,94.414,26.895c8.481,13.991,10.675,34.271,11.636,50.188
		C172.343,120.223,173.405,129.688,169.919,137.191z M184.575,97.159c-1.366,1.529-3.296,2.452-5.243,2.946
		c-0.966,0.245-1.883,0.036-2.805-0.188c-0.77-6.73-1.822-13.379-3.556-19.997c5.568,0.585,11.98,1.104,14.375,7.205
		C188.798,90.821,187.054,94.385,184.575,97.159z"
      />
      <path
        d="M154.228,78.992c-8.435-26.365-33.147-38.912-59.791-38.868C73.225,40.16,41.827,55.251,40.68,79.663
		c-0.976,0.364-1.423,1.856-0.281,2.422c32.227,15.974,79.731,21.366,111.585,1.513c0.616-0.384,0.977-0.954,1.126-1.576
		C154.034,81.347,154.631,80.255,154.228,78.992z M43.883,80.214c7.93-23.501,31.713-34.869,55.138-34.873
		c22.562-0.003,40.036,14.623,49.002,34.094C112.268,93.333,80.312,89.385,43.883,80.214z"
      />
      <path
        d="M57.958,110.979c-1.265-7.036-11.584-13.635-18.019-8.819c-0.151,0.113-0.26,0.246-0.378,0.375
		c-3.504,0.496-6.871,5.215-7.427,8.388c-1.294,7.389,4.111,13.533,11.362,14.015C51.704,125.484,59.503,119.578,57.958,110.979z
		 M43.496,118.963c-4.143-0.086-5.813-3.653-5.778-7.283c0.029-2.954,1.703-4.229,3.802-5.287c2.325,0.094,4.456-0.387,6.689,0.697
		c1.799,0.874,3.204,2.489,3.877,4.36C54.038,116.877,47.786,119.051,43.496,118.963z"
      />
      <path
        d="M157.789,106.733c-2.521-2.346-8.151-4.828-11.271-2.556c-0.21,0.153-0.384,0.364-0.545,0.582
		c-5.029-1.441-9.608,4.951-10.249,9.451c-0.979,6.867,2.607,14.038,9.997,14.917c8.205,0.977,15.666-4.804,16.108-13.095
		C162.015,112.538,160.295,109.064,157.789,106.733z M146.407,122.745c-3.873-0.168-4.95-4.65-4.78-7.735
		c0.2-3.636,2.169-5.655,4.914-7.207c1.795,1.2,3.974,1.226,5.88,2.396c1.731,1.062,2.724,3.225,2.862,5.183
		C155.64,120.381,150.828,122.937,146.407,122.745z"
      />
    </svg>
  ),
  make: (
    <svg
      style={{ width: "inherit", height: "inherit" }}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.87171 1.58811C4.18324 1.9184 4.34293 2.75714 4.79289 3.20711L9.08579 7.5L7.5 9.08579L3.20711 4.79289C2.75711 4.3429 1.90944 4.19971 1.58419 4.87995C0.985604 6.1319 0.769329 7.54124 0.975579 8.9287C1.23113 10.6478 2.11708 12.2104 3.46105 13.3124C4.80502 14.4144 6.51089 14.977 8.24676 14.8908C8.89048 14.8588 9.52124 14.7385 10.1228 14.537L17.8787 22.2929C19.0503 23.4645 20.9497 23.4645 22.1213 22.2929L22.2929 22.1213C23.4645 20.9497 23.4645 19.0503 22.2929 17.8787L14.537 10.1228C14.7395 9.51835 14.8601 8.8844 14.8913 8.2375C14.9753 6.50151 14.4104 4.79638 13.3066 3.45387C12.2028 2.11135 10.6391 1.22746 8.91964 0.974169C7.53192 0.769745 6.12286 0.987876 4.87171 1.58811ZM12.0945 10.5087C12.5356 9.7995 12.793 8.98448 12.834 8.13802C12.8932 6.91285 12.4946 5.70946 11.7156 4.76198C10.9366 3.8145 9.83297 3.19069 8.61946 3.01193C8.21216 2.95193 7.80225 2.94351 7.39883 2.98462L10.5 6.08579C11.2811 6.86684 11.281 8.13317 10.5 8.91421L8.91421 10.5C8.13317 11.281 6.86684 11.281 6.08579 10.5L2.98467 7.39888C2.94336 7.8044 2.95209 8.21647 3.01295 8.62584C3.1933 9.8391 3.81856 10.9419 4.76707 11.7196C5.71557 12.4974 6.91949 12.8944 8.14458 12.8336C8.98967 12.7916 9.80314 12.5338 10.511 12.093L11.9946 13.5766L11.9924 13.5782L19.2929 20.8787C19.6834 21.2692 20.3166 21.2692 20.7071 20.8787L20.8787 20.7071C21.2692 20.3166 21.2692 19.6834 20.8787 19.2929L12.0945 10.5087Z"
        fill="currentColor"
      />
    </svg>
  ),
};
